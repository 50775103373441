import React from 'react'
import './styles.css'


const Contact = () => {
  return (
    <section id="contact">
      <h1>Contact Us</h1>
      <p>rogline@rogline.com</p>
      <p>+44 7902955257</p>
    </section>
  )
}

export default Contact
