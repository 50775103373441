import React from 'react'
import './styles.css'

const Main = () => {
  return (
    <section id="main">
    </section>
  )
}

export default Main
