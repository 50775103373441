import React from "react"
import Main from "./components/main"
import About from "./components/about"
import Offer from "./components/offer"
import Contact from "./components/contact"

const App = () => (
  <div>
    <Main />
    <About />
    <Offer />
    <Contact />
  </div>
)

export default App