import React from 'react'
import './styles.css'


const About = () => {
  return (
    <section id="about">
      <h1>About Us</h1>
      <p>Rogline is committed to providing high-quality, tailored IT solutions to our clients. We strive to exceed expectations, ensuring all tasks are completed with the highest degree of professionalism and dedication.</p>
    </section>
  )
}

export default About
