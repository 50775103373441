import React from 'react'
import './styles.css'


const Offer = () => {
  return (
    <section id="offer">
      <h1>Our Offer</h1>
      <h3>Full Stack Development</h3>
        <p>We provide comprehensive full stack development services, from backend to frontend, delivering robust, scalable, and performant applications tailored to your business needs. With our team's expertise in various programming languages and frameworks, we can craft solutions that perfectly fit your requirements.
        </p>
        <h3>DevOps Solutions</h3>
        <p>Smooth and efficient workflows are crucial for any successful project. We offer DevOps solutions to automate and streamline your IT operations, achieving faster deployment times, improved collaboration, and high-quality software.</p>
        <h3>Quality Assurance</h3>
        <p>We are committed to delivering bug-free and efficient software. Our comprehensive quality assurance services ensure the reliability, security, and performance of your applications, ensuring they meet the highest standards of excellence.</p>
        <h3>Product and Customer Support</h3>
        <p>We understand the importance of product support and customer satisfaction. We provide round-the-clock product support services to ensure smooth operations and quick issue resolution. Our focus on customer service means we're always here when you need us.</p>
        <h3>IT Process Consulting</h3>
        <p>Our team can guide you through the entire IT process - from gathering requirements, to system design, to maintenance. We can help optimize your workflows, making your business operations more efficient and cost-effective.</p>
        <h3>Problem Solving</h3>
        <p>We love challenges and have a passion for problem-solving. If your business is facing a complex issue, our team is ready to develop a unique, innovative solution that adds value to your operations.</p>
    </section>
  )
}

export default Offer
